import { getServerRequest, putServerRequest, patchServerRequest, deleteServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerEvaluacionCursoService(tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/${idCurso}/evaluacion/secciones`;
    return await getServerRequest(url, config);
}

export async function agregarSeccionCursoService(data, tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/${idCurso}/evaluacion/seccion`;
    return await putServerRequest(url, data, config);
}

export async function editarSeccionCursoService(data, tipoCurso, idCurso, idSeccion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/${idCurso}/evaluacion/seccion/${idSeccion}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarSeccionCursoService(tipoCurso, idCurso, idSeccion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/${idCurso}/evaluacion/seccion/${idSeccion}`;
    return await deleteServerRequest(url, config);
}

export async function agregarCriterioCursoService(data, tipoCurso, idCurso, idSeccion) {

    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/${idCurso}/evaluacion/criterio/${idSeccion}`;

    return await putServerRequest(url, data, config);
}

export async function editarCriterioCursoService(data, tipoCurso, idCurso, idCriterio) {

    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/${idCurso}/evaluacion/criterio/${idCriterio}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarCriterioCursoService(idCurso, tipoCurso, idCriterio) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/cursos/${tipoCurso}/${idCurso}/evaluacion/criterio/${idCriterio}`;
    return await deleteServerRequest(url, config);
}